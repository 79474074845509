import React, { Fragment } from 'react'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'
import { AppConstant } from '../../../../AppConstant'
import { hireDedicatedService, metaHireDedicated } from '../../../../Utilites'

const HireDedicated = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaHireDedicated}/>
        <TopScreen breadcrumbDetails={hireDedicatedService}/>
        <CommonService serviceData={AppConstant.detailServices.hireDedicated}/>
    </Fragment>
  )
}

export default HireDedicated