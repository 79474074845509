export const AppConstant = {
    ROUTES:{
        ABOUT:'about',
        CONTACT:'contact',
        SERVICES:{
            PROVIDE_SERVICES: 'services',
            DIGITAL_MARKETING: 'digital-marketing-service',
            WEB_DEVELOPMENT: 'web-development-service',
            APP_DEVELOPMENT: 'mobile-app-development-service',
            AI_DEVELOPMENT: 'ai-development-service',
            IT_CONSULTING: 'it-consulting-service',
            ECOMMERCE: 'ecommerce-development-service',
            UI_DESIGN: 'ui-ux-development-service',
            HIRE_DEDICATED: 'hire-dedicated-service'
        }
    },
    EMAIL:{
        title:'info@metafoxtech.com',
        id:'info@metafoxtech.com'
    },
    CALL:{
        number:'6352118120'
    },
    servicesListSidebar: [
        {title:'Digital Marketing', link: 'digital-marketing-service'},
        {title:'Web Development', link: 'web-development-service'},
        {title:'Mobile App Development', link: 'mobile-app-development-service'},
        {title:'Ai Development', link: 'ai-development-service'},
        {title:'IT Consulting & Strategy', link: 'it-consulting-service'},
        {title:'E-Commerce Development', link: 'ecommerce-development-service'},
        {title:'UI/UX Development', link: 'ui-ux-development-service'},
        {title:'Hire Dedicated', link: 'hire-dedicated-service'},
    ],
    detailServices: {
        digitalMarketing: [
            {
                main_title: 'Comprehensive Digital Marketing Services',
                image_url: 'assets/img/services/details/digital-marketing-service.png',
                section_one_title:'A Full Suite of Digital Marketing Solutions.',
                section_one_title_description: `We offer a comprehensive range of digital marketing services to help you achieve your business goals. 
                Our expert team can assist you with:`,
                detail_lists: [
                    {
                        service_title:'Search Engine Optimization (SEO):',
                        span_detail: `Improve your website's visibility on search engines through strategic keyword optimization,
                                      technical SEO, and link building.`
                    },
                    {
                        service_title:'Pay-Per-Click (PPC) Advertising:',
                        span_detail: `Drive targeted traffic to your website with paid advertising campaigns on Google Ads 
                                      and social media platforms.`
                    },
                    {
                        service_title:'Social Media Marketing:',
                        span_detail: `Engage your audience on popular social media platforms like Facebook, Instagram, Twitter, and LinkedIn.`
                    },
                    {
                        service_title:'Content Marketing:',
                        span_detail: `Create high-quality content, such as blog posts, articles, and videos, to attract and retain customers.`
                    },
                    {
                        service_title:'Email Marketing:',
                        span_detail: `Build strong customer relationships through targeted email campaigns.`
                    },
                    {
                        service_title:'Analytics and Reporting:',
                        span_detail: `Track and measure the performance of your marketing campaigns with advanced analytics tools.`
                    }
                ],
                section_two_title:'Why Choose',
                section_two_title_span:'MetaFox?',
                why_choose_meta_fox: [
                    {
                        label:'Experienced Team:',
                        description:'Our team of digital marketing experts has years of experience.'
                    },
                    {
                        label:'Data-Driven Approach:',
                        description:'We use data to make informed decisions and optimize campaigns.'
                    },
                    {
                        label:'Customized Solutions:',
                        description:'We tailor our strategies to meet your specific needs.'
                    },
                    {
                        label:'Transparent Reporting:',
                        description:'We provide clear and concise reports on your campaign performance.'
                    }
                ],
                section_three_title:'Our Process',
                section_three_title_description:'Our proven process ensures that your marketing efforts are effective and efficient:',
                section_three: [
                    {
                        label:'Discovery:',
                        description:`We work closely with you to understand your business goals and target audience.`
                    },
                    {
                        label:'Strategy:',
                        description:`We develop a customized marketing strategy that aligns with your business objectives.`
                    },
                    {
                        label:'Implementation:',
                        description:`We execute your marketing plan, including content creation, SEO, PPC, social media, and email marketing.`
                    },
                    {
                        label:'Optimization:',
                        description:`We continuously monitor and optimize your campaigns to improve performance.`
                    }
                ]
            },
        ],
        webDevelopment: [
            {
                main_title: 'MetaFox Crafting Digital Experiences that Captivate',
                image_url: 'assets/img/services/details/web-development-service.png',
                section_one_title:'Your Partner in Digital Innovation.',
                section_one_title_description: `At MetaFox, we're passionate about creating exceptional digital experiences. 
                Our team of skilled web developers is dedicated to delivering innovative, user-centric websites that leave a lasting impression.`,
                detail_lists: [
                    {
                        service_title:'Responsive Web Design:',
                        span_detail: `Create websites that adapt seamlessly to any device, ensuring optimal viewing on desktops, tablets, and smartphones.`
                    },
                    {
                        service_title:'Custom Web Development:',
                        span_detail: `Tailor-made solutions to meet your specific business needs, from simple websites to complex web applications.`
                    },
                    {
                        service_title:'E-commerce Development:',
                        span_detail: `Build powerful online stores with advanced features like secure payment gateways, inventory management, and customer account management.`
                    },
                    {
                        service_title:'Web Application Development:',
                        span_detail: `Develop custom web applications to streamline your business processes and enhance productivity.`
                    },
                    {
                        service_title:'Web Maintenance and Support:',
                        span_detail: `Ongoing maintenance and support services to keep your website running smoothly and securely.`
                    },
                ],
                section_two_title:'Why Choose',
                section_two_title_span:'MetaFox?',
                why_choose_meta_fox: [
                    {
                        label:'Expertise and Experience:',
                        description:'Our team has years of experience in web development, ensuring top-notch quality and performance.'
                    },
                    {
                        label:'Cutting-Edge Technologies:',
                        description:'We stay up-to-date with the latest web development trends and technologies to deliver cutting-edge solutions.'
                    },
                    {
                        label:'User-Centric Design:',
                        description:'We prioritize user experience, creating intuitive and engaging websites that drive conversions.'
                    },
                    {
                        label:'SEO Optimization:',
                        description:'We optimize your website for search engines to improve visibility and attract more organic traffic.'
                    },
                    {
                        label:'Responsive Support:',
                        description:`We provide prompt and efficient support to address your needs and ensure your website's success.`
                    }
                ],
                section_three_title:`Let's Build Your Digital Future Together`,
                section_three_title_description: `Ready to take your online presence to the next level? Contact us today to discuss your web development project.
                We'll work closely with you to understand your goals and deliver a website that exceeds your expectations.`,
                section_three: [
                    
                ]
            },
        ],
        appDevelopment: [
            {
                main_title: 'Your Mobile App Development Partner',
                image_url: 'assets/img/services/details/mobile-app-development-service.png',
                section_one_title:'Your Vision, Our Expertise.',
                section_one_title_description: `At MetaFox, we transform ideas into reality. 
                Our team of skilled mobile app developers specializes in crafting intuitive, feature-rich apps that captivate your audience.`,
                detail_lists: [
                    {
                        service_title:'Native App Development:',
                        span_detail: `Build high-performance apps for iOS and Android platforms, leveraging platform-specific features.`
                    },
                    {
                        service_title:'Cross-Platform App Development:',
                        span_detail: `Develop apps that work seamlessly across multiple platforms using frameworks like React Native and Flutter.`
                    },
                    {
                        service_title:'Hybrid App Development:',
                        span_detail: `Combine the best of web and native technologies to create cost-effective and versatile apps.`
                    },
                    {
                        service_title:'Mobile App Design and UX:',
                        span_detail: `Design stunning, user-friendly interfaces that enhance user experience and drive engagement.`
                    },
                    {
                        service_title:'Mobile App Testing and Quality Assurance:',
                        span_detail: `Rigorous testing to ensure app performance, reliability, and security.`
                    },
                    {
                        service_title:'App Store Optimization (ASO):',
                        span_detail: `Optimize your app's visibility on app stores to attract more downloads and increase organic growth.`
                    },
                    {
                        service_title:'Mobile Backend Development:',
                        span_detail: `Ongoing maintenance and support to keep your app up-to-date and performing optimally.`
                    },
                    {
                        service_title:'Mobile App Maintenance and Support:',
                        span_detail: `Ongoing maintenance and support to keep your app up-to-date and performing optimally.`
                    }
                ],
                section_two_title:'Why Choose',
                section_two_title_span:'MetaFox?',
                why_choose_meta_fox: [
                    {
                        label:'Expertise and Experience:',
                        description:'Our team has a proven track record of delivering successful mobile app projects.'
                    },
                    {
                        label:'Cutting-Edge Technologies:',
                        description:'We stay up-to-date with the latest web development trends and technologies to deliver cutting-edge solutions.'
                    },
                    {
                        label:'User-Centric Design:',
                        description:'We leverage the latest technologies to build innovative and future-proof apps.'
                    },
                    {
                        label:'User-Centric Design:',
                        description:'We prioritize user experience to create apps that are easy to use and visually appealing.'
                    },
                    {
                        label:'Agile Development Methodology:',
                        description:`We follow agile methodologies to ensure efficient and flexible development processes.`
                    },
                    {
                        label:'Data-Driven Approach:',
                        description:`We use data analytics to make informed decisions and optimize app performance.`
                    },
                    {
                        label:'Strong Client Partnerships:',
                        description:`We collaborate closely with our clients to understand their unique needs and deliver tailored solutions.`
                    }
                ],
                section_three_title:`Let's Build Your Mobile App Future`,
                section_three_title_description: `Ready to take your business to the next level with a powerful mobile app? Contact us today to discuss your project requirements.
                Our team will work closely with you to bring your vision to life.`,
                section_three: [
                    
                ]
            },
        ],
        aiDevelopment: [
            {
                main_title: 'Elevate Your Business with AI',
                main_title_description:`In today's rapidly evolving digital landscape, 
                artificial intelligence (AI) is no longer a futuristic concept but a powerful tool that can transform businesses. At MetaFox, 
                we harness the potential of AI to deliver innovative solutions that drive growth and efficiency.`,
                image_url: 'assets/img/services/details/AI-development.png',
                main_title_description_two: `Our team of experienced AI experts specializes in developing cutting-edge AI solutions tailored to your specific needs. 
                From machine learning and natural language processing to computer vision and predictive analytics,
                 we have the expertise to bring your AI vision to life.`,
                section_one_title:'Innovative Solutions, Exceptional Results.',
                section_one_title_description: ``,
                detail_lists: [
                    {
                        service_title:'AI Consulting:',
                        span_detail: `Our expert consultants will work closely with you to understand your business goals and identify opportunities to leverage AI. 
                        We provide strategic guidance, feasibility assessments, and technology recommendations to help you make informed decisions.`
                    },
                    {
                        service_title:'Custom AI Development:',
                        span_detail: `We build bespoke AI solutions that are tailored to your unique requirements. 
                        Our team of skilled developers will work with you to design, develop, and deploy AI models that deliver real value.`
                    },
                    {
                        service_title:'AI Integration:',
                        span_detail: `We seamlessly integrate AI into your existing systems and workflows, minimizing disruption and maximizing impact. 
                        Our integration experts will ensure a smooth transition and optimize your AI solutions for optimal performance.`
                    },
                    {
                        service_title:'AI Training and Support:',
                        span_detail: `We offer comprehensive training programs to empower your team to leverage AI effectively. 
                        Our ongoing support services ensure that your AI solutions remain up-to-date and perform optimally.`
                    },
                ],
                section_two_title:'Why Choose',
                section_two_title_span:'MetaFox?',
                why_choose_meta_fox: [
                    {
                        label:'Proven Expertise:',
                        description:'Our team of AI experts has a proven track record of delivering successful AI projects across various industries.'
                    },
                    {
                        label:'Cutting-Edge Technology:',
                        description:'We stay up-to-date with the latest advancements in AI and machine learning to provide innovative solutions.'
                    },
                    {
                        label:'Client-Centric Approach:',
                        description:'We prioritize your needs and work closely with you to understand your business goals and challenges.'
                    },
                    {
                        label:'Data-Driven Insights:',
                        description:'We leverage data analytics to uncover valuable insights and make informed decisions.'
                    },
                    {
                        label:'Ethical AI:',
                        description:`We adhere to ethical principles and ensure responsible AI development.`
                    }
                ],
                section_three_title:`Let's Build the Future with MetaFox`,
                section_three_title_description: `Ready to unlock the power of AI? Contact us today to discuss your project requirements. 
                Our team will work closely with you to bring your AI vision to life.`,
                section_three: []
            },
        ],
        itConsulting: [
            {
                main_title: 'Elevate Your Business with Expert IT Consulting and Strategy',
                main_title_description:`In today's rapidly evolving digital landscape, effective IT strategy is crucial for business success. At MetaFox, 
                we provide comprehensive IT consulting and strategy services to help organizations of all sizes achieve their goals.`,
                image_url: 'assets/img/services/details/IT-Consulting-service.png',
                main_title_description_two: ``,
                section_one_title:'Our team of experienced IT experts offers a wide range of services, including:',
                section_one_title_description: ``,
                detail_lists: [
                    {
                        service_title:'IT Strategy Consulting:',
                        span_detail: `Develop a robust IT strategy aligned with your business objectives.`
                    },
                    {
                        service_title:'Cloud Migration and Management:',
                        span_detail: `Seamlessly transition to the cloud and optimize your IT infrastructure.`
                    },
                    {
                        service_title:'Cybersecurity Solutions:',
                        span_detail: `Protect your business from cyber threats with advanced security measures.`
                    },
                    {
                        service_title:'IT Support and Managed Services:',
                        span_detail: `Receive 24/7 support and maintenance for your IT systems.`
                    },
                ],
                section_two_title:'Why Choose',
                section_two_title_span:'MetaFox?',
                why_choose_meta_fox: [
                    {
                        label:'Proven Expertise:',
                        description:'Our team has a proven track record of delivering successful IT projects.'
                    },
                    {
                        label:'Cutting-Edge Technology:',
                        description:'We stay up-to-date with the latest IT trends and technologies.'
                    },
                    {
                        label:'Client-Centric Approach:',
                        description:'We prioritize your needs and tailor our services to your specific requirements.'
                    },
                    {
                        label:'Cost-Effective Solutions:',
                        description:`We provide cost-effective solutions without compromising quality.`
                    }
                ],
                section_three_title:`Ready to Transform Your Business?`,
                section_three_title_description: `Contact us today to schedule a consultation and learn how our IT consulting and strategy services can help you achieve your goals.`,
                section_three: []
            },
        ],
        eCommerce:[
            {
                main_title: 'Boost Your Online Presence with Professional E-commerce Development',
                main_title_description: `In the digital age, having a strong online store is essential for business growth. At MetaFox, 
                we specialize in building customized, high-performance e-commerce solutions to help you reach and engage customers effectively.`,
                image_url: 'assets/img/services/details/Ecommerce-developmet-service.png',
                main_title_description_two: ``,
                section_one_title: 'Our e-commerce development services include:',
                section_one_title_description: ``,
                detail_lists: [
                    {
                        service_title: 'Custom E-commerce Website Development:',
                        span_detail: `Create a fully customized e-commerce platform tailored to your brand and business needs.`
                    },
                    {
                        service_title: 'Mobile-Optimized E-commerce Solutions:',
                        span_detail: `Design mobile-friendly stores that provide an exceptional shopping experience on any device.`
                    },
                    {
                        service_title: 'Payment Gateway Integration:',
                        span_detail: `Implement secure and seamless payment systems to allow easy transactions for your customers.`
                    },
                    {
                        service_title: 'E-commerce SEO Optimization:',
                        span_detail: `Optimize your online store for search engines to drive more organic traffic and increase sales.`
                    },
                    {
                        service_title: 'Product Management and Catalog Integration:',
                        span_detail: `Easily manage your products and inventories with integrated systems that streamline your e-commerce operations.`
                    },
                    {
                        service_title: 'Secure Shopping Experience:',
                        span_detail: `Ensure a secure shopping environment for your customers with SSL encryption and data protection.`
                    },
                    {
                        service_title: 'E-commerce Platform Migration:',
                        span_detail: `Migrate your existing store to a more scalable, feature-rich e-commerce platform.`
                    }
                ],
                section_two_title: 'Why Choose',
                section_two_title_span: 'MetaFox for E-commerce Development?',
                why_choose_meta_fox: [
                    {
                        label: 'Tailored Solutions:',
                        description: 'We create e-commerce websites and applications that are specifically designed to meet the unique needs of your business.'
                    },
                    {
                        label: 'End-to-End Services:',
                        description: 'From initial design to ongoing maintenance, we provide comprehensive e-commerce development services.'
                    },
                    {
                        label: 'Scalable and Future-Proof:',
                        description: 'Our solutions are built to grow with your business, easily adapting to changing needs and technologies.'
                    },
                    {
                        label: 'User-Centric Design:',
                        description: 'We focus on creating a seamless, intuitive shopping experience that keeps customers coming back.'
                    },
                    {
                        label: 'Security and Compliance:',
                        description: 'We prioritize the security of your customers and your business by adhering to the latest security standards and compliance regulations.'
                    }
                ],
                section_three_title: `Ready to Launch Your Online Store?`,
                section_three_title_description: `Contact us today to schedule a consultation and explore how our e-commerce development services can help you grow your online business.`,
                section_three: []
            }
        ],
        uiDesign:[
            {
                main_title: 'Enhance User Experience with Exceptional UI/UX Design Services',
                main_title_description: `In today's competitive digital landscape, the user experience (UX) and user interface (UI) design are pivotal to the success of any application or website.
                At MetaFox, we specialize in crafting beautifully designed, highly functional, and intuitive UI/UX solutions that engage users, streamline interactions, and deliver superior user satisfaction.`,
                main_title_description_two: `We focus on creating designs that resonate with your target audience, ensuring that every digital touchpoint enhances your brand’s reputation and drives conversions.`,
                image_url: 'assets/img/services/details/ui-ux-design-service.png',
                section_one_title: 'Our UI/UX Design Services Include:',
                section_one_title_description: ``,
                detail_lists: [
                    {
                        service_title: 'Custom User Interface (UI) Design:',
                        span_detail: `We design visually appealing and intuitive interfaces tailored to your brand’s unique style and the needs of your users. 
                        From color schemes to typography and layout design, we ensure every element is thoughtfully crafted to create an aesthetically pleasing and functional experience.`
                    },
                    {
                        service_title: 'User Experience Research & Strategy:',
                        span_detail: `Our team conducts in-depth user research to understand the behavior, needs, and pain points of your target audience. 
                        By gathering insights from real users, we design strategies that deliver seamless, intuitive experiences and ensure your product is easy to navigate and delightful to use.`
                    },
                    {
                        service_title: 'Wireframing & Prototyping:',
                        span_detail: `We build low-fidelity wireframes and high-fidelity prototypes to visualize your design early in the process. This allows for effective collaboration and feedback, ensuring the design matches your vision while maintaining usability.`
                    },
                    {
                        service_title: 'Responsive Design:',
                        span_detail: `We create designs that adapt to all devices and screen sizes, ensuring a seamless experience whether users are browsing on desktop, tablet, or mobile. 
                        Our responsive UI design ensures your application looks and performs flawlessly on any device.`
                    },
                    {
                        service_title: 'Interaction Design & Animation:',
                        span_detail: `Engage your users with dynamic interactions and smooth animations.
                        Our interaction design focuses on creating intuitive user flows and delightful micro-interactions that enhance usability and create a memorable experience.`
                    },
                    {
                        service_title: 'Usability Testing & Optimization:',
                        span_detail: `We conduct usability tests to identify any potential barriers in the user journey. 
                        By analyzing real user data, we iterate on design improvements and continuously optimize the user experience to maximize satisfaction and usability.`
                    },
                    {
                        service_title: 'Design Systems & Style Guides:',
                        span_detail: `To ensure consistency across your brand and products, we develop comprehensive design systems and style guides. 
                        This helps maintain uniformity in UI elements, colors, fonts, and components, fostering a cohesive and professional user experience.`
                    }
                ],
                section_two_title: 'Why Choose',
                section_two_title_span: 'MetaFox for UI/UX Design?',
                why_choose_meta_fox: [
                    {
                        label: 'User-Centered Design Philosophy:',
                        description: `We believe that great design starts with understanding the users. 
                        Our design process is deeply rooted in user research and testing, ensuring the final product resonates with your audience and meets their needs effectively.`
                    },
                    {
                        label: 'Collaborative Approach:',
                        description: `We work closely with you throughout the design process. From initial concepts to final prototypes, we ensure that your vision is at the core of the design while also leveraging our expertise in UI/UX best practices.`
                    },
                    {
                        label: 'Innovative and Creative Solutions:',
                        description: `Our team stays on top of the latest design trends and emerging technologies, enabling us to deliver cutting-edge solutions that set your brand apart. Whether it’s interactive elements, animations, or new navigation patterns, we bring fresh ideas that make a lasting impression.`
                    },
                    {
                        label: 'Responsive & Accessible Designs:',
                        description: `We create designs that not only look great but also ensure accessibility for all users. Our responsive designs guarantee that your users enjoy a consistent experience on all devices, while our accessibility-first approach ensures inclusivity for users with diverse abilities.`
                    },
                    {
                        label: 'Data-Driven Design Decisions:',
                        description: `We rely on real user data and insights to drive our design choices. With user testing, analytics, and feedback, we optimize every detail of the design to ensure it aligns with user behavior and enhances engagement.`
                    },
                    {
                        label: 'End-to-End Service:',
                        description: `From the very first concept to final design implementation, we provide full-cycle UI/UX design services. Whether you need a complete overhaul or a minor redesign, we deliver tailored solutions that elevate your user experience and drive business results.`
                    }
                ],
                section_three_title: `Ready to Transform Your User Experience?`,
                section_three_title_description: `Contact us today to schedule a consultation and discover how our UI/UX design services can elevate your digital product. We will work closely with you to ensure a seamless, engaging, and user-friendly experience that drives growth and enhances your brand reputation.`,
                section_three: []
            }
        ],
        hireDedicated: [
            {
                main_title: 'Hire Dedicated Developers for High-Quality, Tailored Solutions at MetaFox',
                main_title_description: `In today’s rapidly evolving digital landscape, businesses need specialized, high-quality development services to stay ahead of the competition. At MetaFox, we offer expert developers who can help you turn your ideas into reality. Whether you're looking for custom web development, mobile app development, UI/UX design, or enterprise solutions, our dedicated team is here to build impactful, scalable, and secure products.`,
                main_title_description_two: `We focus on delivering exceptional solutions that align with your unique business requirements, ensuring that every development decision contributes to your growth and success in the digital world.`,
                image_url: 'assets/img/services/details/dedicated-developer-service.png',
                section_one_title: 'Our Dedicated Development Services Include:',
                section_one_title_description: ``,
                detail_lists: [
                    {
                        service_title: 'Custom Software Development:',
                        span_detail: `At MetaFox, we specialize in creating robust and scalable custom software solutions tailored to your business needs. Whether you need enterprise-level applications, CRMs, or business automation systems, our dedicated developers build solutions that deliver real value and drive efficiency in your business operations.`
                    },
                    {
                        service_title: 'Web Development:',
                        span_detail: `Our dedicated web developers at MetaFox are skilled in building responsive, high-performance websites using the latest frameworks and technologies. From dynamic landing pages to complex enterprise websites, we ensure your web presence is not only functional but also optimized for user engagement and conversion.`
                    },
                    {
                        service_title: 'Mobile App Development:',
                        span_detail: `Our dedicated mobile app developers create seamless, intuitive apps for iOS and Android platforms. We prioritize performance, user experience, and scalability, ensuring your mobile app delivers a smooth experience and aligns perfectly with your business objectives.`
                    },
                    {
                        service_title: 'UI/UX Design:',
                        span_detail: `Enhance your user engagement and satisfaction with our dedicated UI/UX design services. Our team crafts visually appealing and intuitive user interfaces that focus on providing an exceptional user experience, ultimately boosting customer retention and conversion rates.`
                    },
                    {
                        service_title: 'Cloud Solutions:',
                        span_detail: `Our cloud development team helps you migrate, manage, and scale your applications on top cloud platforms like AWS, Google Cloud, or Microsoft Azure. We ensure that your cloud infrastructure is optimized for performance, security, and cost-efficiency, giving your business a competitive edge.`
                    },
                    {
                        service_title: 'E-commerce Development:',
                        span_detail: `Build robust and secure e-commerce platforms with MetaFox. From custom online stores to full-fledged e-commerce ecosystems, we create solutions that streamline your business operations and provide a seamless shopping experience for your customers.`
                    },
                    {
                        service_title: 'Maintenance & Support:',
                        span_detail: `Our dedicated developers offer continuous maintenance and support services to ensure your digital products run smoothly. From bug fixes and security patches to updates and optimizations, we help you keep your application up-to-date and performing at its best.`
                    }
                ],
                section_two_title: 'Why Choose MetaFox for Dedicated Development?',
                section_two_title_span: 'MetaFox for Expert Development Solutions',
                why_choose_meta_fox: [
                    {
                        label: 'Expertise Across Technologies:',
                        description: `MetaFox’s developers have a deep understanding of various technology stacks, enabling us to tackle complex projects and deliver cutting-edge solutions. Whether it’s front-end, back-end, full-stack, or mobile development, we bring the expertise needed to succeed in any area of development.`
                    },
                    {
                        label: 'Tailored Solutions for Your Business:',
                        description: `We understand that no two businesses are the same. That’s why we work closely with you to deliver custom solutions that meet your specific goals, objectives, and challenges. From initial planning to final delivery, we ensure every step is aligned with your business needs.`
                    },
                    {
                        label: 'Seamless Collaboration & Communication:',
                        description: `At MetaFox, we believe in maintaining transparent and constant communication with our clients. Our developers are dedicated to ensuring that you are always in the loop, providing you with regular updates and allowing you to give feedback throughout the process.`
                    },
                    {
                        label: 'Scalability & Flexibility:',
                        description: `Whether you're scaling your team up for a short-term project or need long-term development support, MetaFox offers flexibility and scalability. Our dedicated developers seamlessly integrate with your team to provide the expertise needed at any stage of your project.`
                    },
                    {
                        label: 'Focus on Quality & Performance:',
                        description: `We are committed to delivering high-quality, high-performance solutions that stand the test of time. Our developers follow best practices for coding, testing, and deployment to ensure that the final product is reliable, secure, and optimized for performance.`
                    },
                    {
                        label: 'End-to-End Services:',
                        description: `From the first concept to final deployment, MetaFox provides end-to-end development services. We cover every aspect of the development cycle, ensuring that all parts of your project are delivered seamlessly, with no gaps between development phases.`
                    }
                ],
                section_three_title: 'Ready to Build Your Dream Project?',
                section_three_title_description: `Contact MetaFox today to schedule a consultation and discover how our dedicated developers can help you build innovative, secure, and scalable digital solutions. We are committed to turning your ideas into high-performing applications that drive your business forward.`,
                section_three: []
            }
        ]        
    }
}