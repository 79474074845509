import React from "react";
import { Link } from "react-router-dom";
import { AppConstant } from "../../AppConstant";

const MenuLinks = ({closeMobileMenu}) => {
  return (
    <nav id="mobile-menu" className="tp-main-menu-content">
      <ul>
        <li className="has-dropdown">
          <Link to="/" onClick={closeMobileMenu}>Home</Link>
        </li>
        <li className="has-dropdown">
          <Link to="/about" onClick={closeMobileMenu}>About</Link>
        </li>
        <li className="has-dropdown">
          <a href="javascript:void(0);" onClick={closeMobileMenu}>Services</a>
          <ul className="tp-submenu">
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.DIGITAL_MARKETING}`}>Digital Marketing</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.WEB_DEVELOPMENT}`}>Web Development</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.APP_DEVELOPMENT}`}>Mobile App Development</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.AI_DEVELOPMENT}`}>Ai Development</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.IT_CONSULTING}`}>It Consulting Service</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.ECOMMERCE}`}>E-commerce Development</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.UI_DESIGN}`}>UI-UX Development</Link>
            </li>
            <li>
              <Link onClick={closeMobileMenu} to={`/${AppConstant.ROUTES.SERVICES.HIRE_DEDICATED}`}>Hire Dedicated Development</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={'/contact'} onClick={closeMobileMenu}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLinks;
