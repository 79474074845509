import React from "react";
import TopScreen from "../common/TopScreen/TopScreen";
import { breadcrumbDetailsContactus } from "../../Utilites";
import {AppConstant} from '../../AppConstant'

const ContactUs = () => {
  return (
    <main>
      <TopScreen breadcrumbDetails={breadcrumbDetailsContactus} />
      <section class="contact-area pt-115 pb-80">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="tp-contact-main mb-40">
                <div class="tp-section tp-section-two mb-25">
                  {/* <span class="tp-section-sub-title">
                    <i class="flaticon-edit"></i> Let’s Talk With Us
                  </span> */}
                  <h4 class="tp-section-title">
                    Grow Your Business With Our Expertise
                  </h4>
                  <div class="tp-section-title-wrapper">
                    <p>
                      We have been taking the market by storm since 2024 and we are proud to boast our happy clients. 
                      With experts working in our team, we have dedicated our efforts and hard work towards the happiness of our clients.
                    </p>
                  </div>
                </div>
                {/* <div class="tp-contact-main-thumb mb-40 w-img">
                  <img src="assets/img/thumbs/contact-main-bg-1.jpg" alt="" />
                </div> */}
                <div class="tp-contact-location-wrap d-flex align-items-center">
                  <div class="tp-contact-location">
                    <span class="tp-contact-location-title">India</span>
                    <a href="#">
                      <i class="fa-light fa-location-dot"></i> Gujarat
                    </a>
                    <a href={`tel:${AppConstant.CALL.number}`}>
                      <i class="fa-light fa-phone"></i>&nbsp;+91 {AppConstant.CALL.number}
                    </a>
                    <a href={`mailto:${AppConstant.EMAIL.id}`}>
                      <i class="fa-light fa-envelope"></i>
                      {AppConstant.EMAIL.title}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="offset-xl-1 col-xl-6 col-lg-6">
              <div class="mb-40">
                <form action="#">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="First Name" />
                        <span>
                          <i class="fa-light fa-user"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="Last Name" />
                        <span>
                          <i class="fa-light fa-user"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="Email Address" />
                        <span>
                          <i class="fa-light fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="Phone Number" />
                        <span>
                          <i class="fa-light fa-phone"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="Company Name" />
                        <span>
                          <i class="fa-light fa-user"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-input mb-20">
                        <input type="text" placeholder="Your Subject" />
                        <span>
                          <i class="fa-sharp fa-light fa-circle-info"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-input mb-20">
                        <textarea
                          name="Message"
                          placeholder="Write Your Message"
                        ></textarea>
                        <span>
                          <i class="fa-light fa-pen"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="tp-contact-details-form-btn">
                        <button class="tp-btn">Send a Message</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
